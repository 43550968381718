import {
  Box,
  Typography,
  styled,
  FormControl,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Button,
  Link,
} from '@material-ui/core'
import { navigate, PageProps } from 'gatsby'
import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import axios from 'axios'

import Navbar from '../../../components/navbar'
import MobileNavbar from '../../../components/navbar/mobile'
import { useCart } from '../../../hooks/useCart'
import { CartType } from '../../../services/api/endpoints/carts'

const StyledFileUploadContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(600)]: {
        width: '100%',
      },
    }
  }}
  display: block;
  width: 50%;
  margin: auto;
  text-decoration: none;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
`

const StyledTypographyTitle = styled(Typography)`
  color: #008174;
  font-weight: bold;
  margin-bottom: 10px;
`

const StyledTypographySubtitle = styled(Typography)`
  color: #3c3835;
  font-size: 18px;
`

const StyledContainer = styled(Box)`
  margin-top: 30px;
  padding: 1rem;
  padding-top: 0;
`

const StyledBox = styled(Box)`
  margin-bottom: 10px;
`

const StyledCheckBoxContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(600)]: {
        fontSize: '0.7rem',
      },
    }
  }}
`
const StyledPrimaryButton = styled(Button)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(600)]: {
        marginTop: '20px',
        marginBottom: '60px',
        width: '100%',
      },
    }
  }}
  background-color: #008174;
  font-weight: bold;
  padding: 10px;
  width: 300px;
  margin-top: 40px;
`

const RegisterPlayerHeadshotPage = (props: PageProps) => {
  const cartType: CartType = 'player'
  const isMobile = useMediaQuery({ query: `(max-width: 945px)` })
  const [isAgree, setIsAgree] = useState(false)
  const [logo, setLogo] = useState<string | undefined>(undefined)
  const [selectedFile, setSelectedFile] = useState(null)

  const { cart, setParticipantDetails } = useCart({
    type: cartType,
  })

  const { handleSubmit } = useForm<any>({
    defaultValues: {},
  })

  const onSubmit: SubmitHandler<any> = () => {
    if (isAgree) {
      handleUpload()
    } else {
      toast.error('You must agree to the TTR Participant Agreement to continue')
    }
  }

  const handleChangeImage = (e: any) => {
    setLogo(URL.createObjectURL(e.target.files[0]))
    setSelectedFile(e.target.files[0])
  }

  const handleUpload = () => {
    if (cart?.data?.participantDetails) {
      if (selectedFile) {
        const fileData = new FormData()
        fileData.append('file', selectedFile!)

        axios
          .post('https://api.tritagrugby.com/participants/image', fileData, {
            headers: {
              accept: 'application/json',
              'Content-Type': `multipart/form-data;`,
            },
          })
          .then(res => {
            // handle success
            console.log(res.data)
            if (res.data) {
              if (res.data.data) {
                const participantDetails = cart.data.participantDetails!
                setParticipantDetails({
                  participantDetails: {
                    ...participantDetails,
                    headshot: res.data.data,
                  },
                  type: cartType,
                })

                navigate('/register/player/order-uniform')
              }
            }
          })
          .catch(() => {
            alert('Something went wrong, Please try again')
            // handle error
          })
      } else {
        navigate('/register/player/order-uniform')
      }
    } else {
      console.error('Failed to update cart. Missing participantDetails.')
    }
  }

  return (
    <>
      {isMobile ? (
        <MobileNavbar bg="#008174" />
      ) : (
        <Navbar path={props.location.pathname} bg="#008174" />
      )}
      <StyledContainer>
        <StyledBox>
          <StyledTypographyTitle variant="h5">
            Upload a headshot
          </StyledTypographyTitle>
          <StyledTypographySubtitle>
            This headshot may be publicly displayed in match centres and
            leaderboards
          </StyledTypographySubtitle>
        </StyledBox>
        <StyledFileUploadContainer>
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <FormGroup
              style={{
                backgroundColor: '#fff',
                borderRadius: '6px',
                boxShadow: '0px 5px 10px 0px #eee',
                padding: '30px 25px',
                marginBottom: '20px',
              }}
            >
              {logo ? (
                <>
                  <input
                    id="fileupload"
                    onChange={handleChangeImage}
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    style={{
                      display: 'block',
                      visibility: 'hidden',
                    }}
                  />
                  <img
                    src={logo}
                    height="300"
                    width="300"
                    alt="headshot"
                    style={{
                      margin: 'auto',
                      backgroundPosition: 'center center',
                      objectFit: 'cover',
                    }}
                    onClick={e => {
                      e.preventDefault()
                      document.getElementById('fileupload')?.click()
                    }}
                  />
                </>
              ) : (
                <FormControl variant="outlined" sx={{ margin: 'auto' }}>
                  <label
                    htmlFor="fileupload"
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    Upload your image
                    <input
                      id="fileupload"
                      onChange={handleChangeImage}
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      style={{
                        display: 'block',
                        visibility: 'hidden',
                      }}
                    />
                    <span
                      style={{
                        fontWeight: 'normal',
                        fontSize: '16px',
                      }}
                    >
                      PNG and JPG file format only
                    </span>
                    <CloudUploadIcon
                      style={{
                        display: 'block',
                        fontSize: '70px',
                        margin: '50px auto',
                      }}
                    />
                  </label>
                </FormControl>
              )}
            </FormGroup>
            <FormGroup>
              <StyledCheckBoxContainer
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAgree}
                      onChange={() => setIsAgree(!isAgree)}
                    />
                  }
                  label=""
                />
                <span style={{ marginRight: '0.5rem', marginLeft: '-1rem' }}>
                  I agree to{' '}
                </span>
                <Link
                  href="https://tritagrugby.com/privacy-policy"
                  target="_blank"
                >
                  Tri Tag Rugby's Privacy Policy
                </Link>
              </StyledCheckBoxContainer>
            </FormGroup>
            <StyledPrimaryButton type="submit" variant="contained">
              Next
              <ArrowForwardIcon
                style={{
                  fontSize: '16px',
                  marginLeft: '10px',
                }}
              />
            </StyledPrimaryButton>
          </Box>
        </StyledFileUploadContainer>
      </StyledContainer>
    </>
  )
}

export default RegisterPlayerHeadshotPage
